// LoginLayout.js
import { useState } from 'react';
import { useSiteContext } from '../../contexts/SiteProvider';
import { CROP_ADMIN_LOGO, CROP_LOGO_LIGHT } from "../../services/ImageService";
import AdminHeader from './AdminHeader';
import './AdminLayoutCss.css';
import AdminSideNav from './AdminSideNav';



const AdminLayout = ({ children }) => {
  
  const { user, setUser ,toggleDark,isDark} = useSiteContext();
  // const [isDark,setIsDark]=useState(false)
  const [page, setPage] = useState("home");
  const [isOpen, setIsOpen] = useState(false);
  const handleLogout = () => {
    setUser();
  }
 
   
  // const toggleDark = () => {
  //   console.log("dark toggle set")
  //   setIsDark(!isDark); 
  // };
  const toggleSidebar = () => {
    setIsOpen(!isOpen); // Toggle the state
  };
  return (
    <div className="container is-fluid smart-crop-admin-container" data-theme={isDark ? "dark" : "light"}>
         <div  className={`sidebar ${isOpen ? 'smart-crop-admin-sidenav-open' : 'smart-crop-admin-sidenav'}`} >
         <div className="is-flex is-justify-content-center mt-5" >  
            {isDark ? <img className="crops-logo" src={CROP_LOGO_LIGHT} alt="" />:
            <img className="crops-logo" src={CROP_ADMIN_LOGO} alt="" />}
        </div > 
        <div className='smart-crop-admin-side-bottom'>
        <AdminSideNav page={page} /> 
        </div>
          
         </div> 
         <div  className={`sidebar ${isOpen ? 'smart-crop-admin-middle-open' : 'smart-crop-admin-middle'}`} >
              <div className='smart-crop-admin-header'>
             
                  <AdminHeader user={user} isDark={isDark} setIsDark={toggleDark} toggleSidebar={toggleSidebar} setPage={setPage} handleLogout={handleLogout} />
              </div>
              <div className='smart-crop-admin-content'>
                  {children}
              </div>
         </div>
    </div>
  );
};

export default AdminLayout;
