import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SmartSoftButton,
  SmartSoftCheckRadioSwitch,
  SmartSoftDate,
  SmartSoftSelect,
  SmartSoftTable,
} from "soft_digi";
import { ADMIN_BIDSECTOR_SECTOR } from "../../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../../contexts/SiteProvider";
import {
  showAlertAutoClose,
  showYesOrNoAlert,
} from "../../../../services/notifyService";
import { post } from "../../../../services/smartApiService";

import {
  changeDateTimeZone,
  formatDate,
  changeDateTimeZoneFormat,
} from "../../../../services/core/CommonService";
import { admin_sector_select } from "../../../../services/site/SelectBoxServices";
import BidAdditionForm from "./BidAdditionForm";

const BidSectorTable = () => {
  const [formData, setFormData] = useState({});
  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState(null);
  const [category, setCategory] = useState("1");
  const [formErrors, setFormErrors] = useState(false);
  const [tabData, setTabData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const navigate = useNavigate();
  const { setLoading, openModal } = useSiteContext();
  const currentDate = new Date();
  const currentDateMinusOneDay = new Date();
  currentDateMinusOneDay.setDate(currentDateMinusOneDay.getDate() - 1);

  const loadTableData = () => {
    setFilterData([]);
    setTabData([]);
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    let data_in = {
      bid_date: formData?.from_date,
      category: category,
    };
    data_in["bid_date"] = changeDateTimeZoneFormat(
      data_in["bid_date"],
      "YYYY-MM-DD"
    );
    const subscription = post(
      ADMIN_BIDSECTOR_SECTOR.GET_ALL_BID_ADMIN,
      data_in,
      handleError
    ).subscribe((response) => {
      // console.log("response " , response);
      setTabData(response.data);
      if (category === "2") {
        setFilterData(response.data);
      }
      // if(category==="1"){
      //   console.log("Top:",response.data)
      //   filter_data()

      // }else{
      //   console.log("Promos:",response.data)
      //   setFilterData(response.data);
      // }

      // setFormData({from_date:currentDate})
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const updateQualifyStatus = (id) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Loading....Please Wait");
    // console.log(sector)
    // console.log(formData)
    // formData["from_date"] = changeDateTimeZone(formData.from_date);
    const subscription = post(
      ADMIN_BIDSECTOR_SECTOR.UPDATE_QUALIFY,
      { id: id, sector: sector.value, start_date: formData.from_date },
      handleError
    ).subscribe((response) => {
      setLoading(false);
      loadTableData();
      showAlertAutoClose(response.data.msg, "success");
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const handle_redirection = (id, selection) => {
    if (selection === "yes") {
      updateQualifyStatus(id);
    }
  };

  const updateQualify = (data) => {
    let id = data.ID !== undefined ? data.ID : 0;
    showYesOrNoAlert(
      "Do you Want to Qualify this Bid ? ",
      (selection) => handle_redirection(id, selection),
      "question"
    );
  };

  useEffect(() => {
    // admin_sector_select((data) => setSectors(data));
    admin_sector_select(function (data) {
      setSectors(data);
      setSector(data[0]);
      filter_data();
    });
    setCategory("1");
  }, []);

  const filter_data = (onSector) => {
    let sector_id;
    sector_id = sector && sector.value !== undefined ? sector.value : 0;
    let _data = [...tabData];
    console.log(sector_id);
    if (sector_id > 0) {
      let _filter_data = _data.filter(
        (item) => item.sd_admin_sector_id === sector_id
      );
      console.log(_filter_data);
      _filter_data.sort((a, b) => a.publish_order - b.publish_order);
      setFilterData(_filter_data);
    } else {
      _data.sort((a, b) => a.publish_order - b.publish_order);
      setFilterData(_data);
    }
  };

  useEffect(() => {
    if (category === "1") {
      filter_data();
    }
    // filter_data();
  }, [sector, tabData]);

  useEffect(() => {
    loadTableData();
  }, [formData, category]);

  useEffect(() => {
    setFormData({ from_date: currentDate });
  }, []);

  const baseNav = "/operations";
  const navigateLink = (index) => {
    navigate(baseNav + "/" + index);
  };

  const statusTags = [
    { value: "SUBMITTED", Label: "SUBMITTED", class: "is-warning" },
    { value: "QUALIFIED", Label: "QUALIFIED", class: "is-info" },
    { value: "PAID", Label: "PAID", class: "is-success" },
    { value: "COMPLIMENTARY", Label: "COMPLIMENTARY", class: "has-text-info" },
    { value: "CANCELLED", Label: "CANCELLED", class: "is-danger" },
    { value: "PUBLISHED", Label: "PUBLISHED", class: "is-success" },
    { value: "ACTIVE", Label: "ACTIVE", class: "is-success" },
  ];

  const deleteList = (id) => {
    showYesOrNoAlert(
      "Do you wish to delete this bid?",
      (selection) => {
        if (selection === "yes") {
          deleteBid(id);
        }
      },
      "danger"
    );
  };

  const deleteBid = (id, action) => {
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Deleting.... Please Wait");
    let url = ADMIN_BIDSECTOR_SECTOR.DELETE;
    let data_in = { id: id.ID };
    const subscription = post(url, data_in, handleError).subscribe(
      (response) => {
        setLoading(false);

        showAlertAutoClose("Bid Deleted Successfully", "success");
        loadTableData();
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  };

  const moveItem = (items, id, direction) => {
    // Find the index of the item to move
    const index = items.findIndex((item) => item.ID === id);
    if (direction === "up" && index > 0) {
      // Swap with the item above it
      [items[index], items[index - 1]] = [items[index - 1], items[index]];

      // Adjust orders
      items[index].publish_order++;
      items[index - 1].publish_order--;
    } else if (direction === "down" && index < items.length - 1) {
      // Swap with the item below it
      [items[index], items[index + 1]] = [items[index + 1], items[index]];

      // Adjust orders
      items[index].publish_order--;
      items[index + 1].publish_order++;
    } else {
      //console.log("Cannot move in that direction");
    }
    return items;
  };

  const orderUpdate = (data, type) => {
    let id = data.ID !== undefined ? data.ID : 0;
    console.log([...tabData]);
    let newData = moveItem([...tabData], id, type);
    console.log(newData);
    data.publish_order = data.publish_order ? parseInt(data.publish_order) : 1;
    data.publish_order =
      type === "down"
        ? parseInt(data.publish_order) + 1
        : parseInt(data.publish_order) - 1;
    //if()
    //console.log("categeory " , category);

    //return false;
    let form_data = {
      bid_date: formData.from_date,
      id: id,
      order: newData,
    };
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setLoading(false);
    };
    setLoading(true, "Details Submitting....Please Wait");
    let url = ADMIN_BIDSECTOR_SECTOR.UPDATE_ORDER;

    const subscription = post(url, form_data, handleError).subscribe(
      (response) => {
        loadTableData();
        showAlertAutoClose(response.data.msg, "success");
        setLoading(false);
      }
    );
    // let modalObject = {
    //   body: (
    //     <BidOrderChangeForm loadTableData={loadTableData} dataIn={form_data} />
    //   ),
    //   modalClass: "crop-admin-modal smart-modal-30",
    //   bodyClose: false,
    // };
    // openModal(modalObject);
  };

  const bidAddition = () => {
    let form_in_data = {
      bid_date: formData?.from_date,
      category: category,
    };
    let modalObject = {
      body: (
        <BidAdditionForm
          loadTableData={loadTableData}
          dataIn={form_in_data}
          sector={sector}
        />
      ),
      modalClass: "crop-admin-modal smart-modal-70",
      bodyClose: false,
    };
    openModal(modalObject);
  };

  const display_action_button = (data) => {
    if (data.status_desc === "SUBMITTED") {
      return (
        <SmartSoftButton
          label="Qualify"
          onClick={() => updateQualify(data)}
          classList={["is-small", "is-danger"]}
        />
      );
    } else if (data.status_desc === "PAID") {
      return (
        <>
          {/* <SmartSoftButton
            leftIcon="fa-file"
            label={data?.publish_order || 1}
            classList={["is-small", "is-danger"]}
          /> */}
        </>
      );
    }
  };

  const display_delete_button = (data) => {
    //
    if (data.payment_mode == "DIRECT" && data.status_desc == "COMPLIMENTARY") {
      return (
        <div className=" " onClick={() => deleteList(data)}>
          <div className="is-small is-flex smart-delete-icon">
            <i className="fa fa-trash  is-size-5 " aria-hidden="true"></i>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const orderUpButtonShow = (data) => {
    return data.status_desc === "PAID" || data.status_desc === "COMPLIMENTARY"
      ? parseInt(data?.publish_order) === 1
        ? true
        : false
      : true;
  };

  const orderDownButtonShow = (data) => {
    if (data.status_desc !== "PAID")
      if (data.status_desc !== "COMPLIMENTARY") return true;
    if (category === "1" && parseInt(data?.publish_order) === 12) {
      return true;
    }
    if (category === "2" && parseInt(data?.publish_order) === 10) {
      return true;
    }
    return false;
  };

  const iconButton = [
    {
      type: "icon",
      leftIcon: "fa fa-arrow-up",
      classList: ["is-clickable"],
      onClick: (data) => {
        orderUpdate(data, "up");
      },
      hideFunction: orderUpButtonShow,
    },
    {
      type: "icon",
      leftIcon: "fa fa-arrow-down",
      classList: ["is-clickable"],
      onClick: (data) => {
        orderUpdate(data, "down");
      },
      hideFunction: orderDownButtonShow,
    },
  ];

  const orderDisplay = (data) => {
    // COMPLIMENTARY
    return data.status_desc === "PAID" ||
      data.status_desc === "COMPLIMENTARY" ||
      data.status_desc === "ACTIVE"
      ? data?.publish_order
      : "";
  };

  const businessNameDisplay = (data) => {
    // return data.business_name
    return (
      <div>
        <p>{data.business_name}</p>
        <p className="">{formatDate(data.created_time, "DD-MM-YYYY")}</p>
      </div>
    );
  };

  const display_market_offer = (data) => {
    let options = [];
    if (data.top_ranking === "Yes") {
      options.push("TOP RANKING");
    }
    if (data.promos === "Yes") {
      options.push("PROMOS");
    }
    return options.join(", ");
  };

  const columns = [
    // {
    //   title: "Order Number",
    //   index: "publish_order",
    //   width: "5",
    //   valueFunction: orderDisplay,
    // },
    // { title: "S.No", index: "publish_order", width: "5" },
    { title: "", width: "" },
    {
      title: "Business Name",
      index: "business_name",
      valueFunction: businessNameDisplay,
    },
    {
      title: "Market For",
      index: "marketfor",
      valueFunction: display_market_offer,
    },
    { title: "Sector", index: "sector_name" },
    { title: "Offer Title", index: "offer_title" },

    { title: "Base (A$)", index: "base_bid_price", isSortable: true },
    { title: "Bid (A$)", index: "bid_price", isSortable: true },
    {
      title: "From Date",
      index: "start_date",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    {
      title: "To Date",
      index: "end_date",
      isSortable: true,
      type: "date",
      dateFormat: "DD-MM-YYYY",
    },
    { title: "Status", index: "status_desc", type: "tags", tags: statusTags },
    {
      title: "Update",
      index: "action",
      valueFunction: display_action_button,
    },
    {
      title: "Action",
      type: "buttons",
      buttons: iconButton,
      classList: ["m-0"],
    },
    {
      title: "",
      index: " ",
      valueFunction: display_delete_button,
    },
  ];

  const handleInputChange = (name, value) => {
    //console.log(" name " ,name , "   value " , value)
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleErrorChange = (name, value) => {
    setFormErrors((prev) => {
      // Create a copy of the previous state
      const updatedFormData = { ...prev };
      // Check if the value is null or empty
      if (value === null || value === "") {
        // If true, remove the property from the updated state
        delete updatedFormData[name];
      } else {
        // Otherwise, update or add the property in the updated state
        updatedFormData[name] = value;
      }

      return updatedFormData;
    });
  };

  const datefield = () => {
    return (
      <div className="is-flex ">
        <SmartSoftDate
          label="Select Date"
          placeHolder="DD/MM/YY"
          value={formData?.from_date || ""}
          onChange={(value) => handleInputChange("from_date", value)}
          inputType="BORDER_LABEL"
          inputProps={{ isFocussed: true }}
          isHorizontal
        />
      </div>
    );
  };

  const selectData = () => {
    return (
      <SmartSoftSelect
        options={sectors}
        onChange={(value) => setSector(value)}
        value={sector}
        label="Sector"
        inputType="BORDER_LABEL"
      />
    );
  };

  const MyOfferSwitch = () => {
    const top_ranks = [{ value: "1", label: "Top Ranks" }];
    const promotion_offer = [{ value: "2", label: "Promotion Offer" }];
    return (
      <>
        <div className="is-flex">
          <div className="my-offer-month-switch is-rounded mr-5">
            <SmartSoftCheckRadioSwitch
              options={top_ranks}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              // classList={"is-rounded"}
              name="switchone"
              value={category}
              onChange={(value) => setCategory("1")}
            />
          </div>
          <div className="my-offer-year-switch">
            <SmartSoftCheckRadioSwitch
              options={promotion_offer}
              switchMode={true}
              isRight={true}
              inputProps={{ isRounded: true }}
              name="switchtwo"
              value={category}
              onChange={(value) => setCategory("2")}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="smart-crop-admin-container crop-admin-form">
        <div key={"BidSectorTablecolumnsone"} className="columns">
          <div key={"BidSectorTablecolumnone"} className="column">
            <div className="card">
              <div
                key={"BidSectorTablecolumnstwo"}
                className="columns is-vcentered"
              >
                <div
                  key={"BidSectorTablecolumntwo"}
                  className="column is-1 crop-admin-table-back"
                >
                  <span className="icon" onClick={() => navigateLink("home")}>
                    <i className="fa fa-undo" aria-hidden="true"></i>
                  </span>
                </div>
                <div
                  key={"BidSectorTablecolumnthree"}
                  className="column is-4 crop-admin-table-title is-flex"
                >
                  {MyOfferSwitch()}
                </div>
                <div key={"BidSectorSelectbox"} className="column is-2">
                  {category === "1" && selectData()}
                </div>

                <div
                  key={"BidSectorTablecolumnfour"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {datefield()}
                </div>
                <div
                  key={"BidSectorTablecolumnfive"}
                  className="column is-1 has-text-right crop-table-top-pagination"
                >
                  {/* {formData.from_date && 
                   currentDateMinusOneDay < formData.from_date && category === "2" && (
                    <SmartSoftButton
                      label="Add"
                      classList={["crop-admin-action-button"]}
                      onClick={() => bidAddition()}
                    />
                  )} */}
                  {formData.from_date &&
                    currentDateMinusOneDay < formData.from_date && (
                      <SmartSoftButton
                        label="Add"
                        classList={["crop-admin-action-button"]}
                        onClick={() => bidAddition()}
                      />
                    )}
                </div>
              </div>
              <div></div>
              <SmartSoftTable
                columns={columns}
                data={filterData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidSectorTable;
