import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartSoftButton, SmartSoftTable } from "soft_digi";
import { ADMIN_OPERATIONS_LOYALTY } from "../../../api/Admin/OperationsUrl";
import { useSiteContext } from "../../../contexts/SiteProvider";
import { showAlertAutoClose } from "../../../services/notifyService";
import { get, post } from "../../../services/smartApiService";

const SiteLog = () => {
  const [tabData, setTabData] = useState([]);
  const { setLoading, openModal, closeModal } = useSiteContext();
  const navigate = useNavigate();
  // this is api to the table data
  const loadTableData = () => {
    setLoading(true, "Loading....Please Wait");
    const handleError = (errorMessage) => {
      showAlertAutoClose(errorMessage, "error");
      setTabData([]);
      setLoading(false);
    };
    const subscription = post(
      "/services/site/get_cron_log",
      { log_date: "2024-09-17" },
      handleError
    ).subscribe((response) => {
      setTabData(response.data);
      //console.table("testing", response.data);
      setLoading(false);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const remarks_value = (data) => {
    if (!data) return null;
  
    return (
      <div className="">
        {data.cropid && <>CROP ID: {data.cropid}</>}
        {data.bid_number && <>Bid Number: {data.bid_number}, </>}
        {data.start_date && <>Start Date: {data.start_date}, </>}
        {data.end_date && <>End Date: {data.end_date}, </>}
        {data.top_ranking && <>Top Ranking: {data.top_ranking}, </>}
        {data.promos && <>Promos: {data.promos}</>}
      </div>
    );
  };
  

  const columns = [
    { title: "", index: "testnew", width: "5" },
    { title: "Date ", index: "datetime",    type: "date",
      dateFormat: "DD-MM-YYYY", width: "10"  },
    { title: "Module", index: "module", width: "20" },
    { title: "Massage ", index: "msg", width: "20"  },
    { title: "Remarks", index: "", valueFunction: remarks_value, width: "45" },
  ];

  return (
    <>
      <div className="smart-crop-admin-container">
        <div key={"LoyalityTableFirstcolumns"} className="columns">
          <div key={"LoyalityTableFirstcolumn"} className="column">
            <div className="card">
              <div
                key={"LoyalityTableSecondcolumns"}
                className="columns is-vcentered"
              >
                <div
                  key={"LoyalityTableSecondcolumn"}
                  className="column is-1 crop-admin-table-back"
                ></div>
                <div
                  key={"LoyalityTableThirdcolumn"}
                  className="column is-8 crop-admin-table-title"
                >
                  Site Log
                </div>

                <div
                  key={"LoyalityTableFourthcolumn"}
                  className="column is-3 has-text-right crop-table-top-pagination"
                >
                  {/* <i className="fa fa-caret-left" aria-hidden="true"></i>{" "} */}
                  {/* <i className="fa fa-caret-right" aria-hidden="true"></i> */}
                </div>
              </div>
              <SmartSoftTable
                columns={columns}
                data={tabData}
                tableProps={{ className: "crop-table-layout" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLog;
